import React, { Suspense } from "react";
import Footer from "../Footer";
import { Box, Spinner, useColorModeValue, Text, Image } from "@chakra-ui/react";
import WIPfay from "../../assets/img/opti/soon/WIP-fay.avif";
const Soon = () => {
  return (
    <>
      <Suspense fallback={<Spinner size={"lg"} />}>
        <Box
          className="Sooner"
          minH={"120vh"}
          minW={{
            md: "110%",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          <Image
            src={WIPfay}
            className="WipFay"
            w={{
              base: "55vh",
              lg: "70vh",
            }}
            minWidth={{
              base: "240px",
            }}
            display={{
              md: "block",
            }}
            position={"relative"}
            left={{
              lg: "6%",
            }}
            top={{
              base: "10%",
              md: "20%",
            }}
            alignSelf={{
              base: "center",
              lg: "unset",
            }}
            zIndex={"20"}
          />
          <Box
            className="Soon font-face-cosmic"
            bg={useColorModeValue("gray.100", "gray.900")}
            color={useColorModeValue("purple.500", "purple.200")}
            position={"absolute"}
            w={"100%"}
            zIndex={"10"}
            top={{
              base: "65%",
              lg: "45%",
            }}
          >
            <Text
              className="wiptxt"
              textAlign={"center"}
              fontSize={{
                base: "8vh",
                md: "10vh",
              }}
            >
              Work in Progress
            </Text>
          </Box>
          <Footer />
        </Box>
      </Suspense>
    </>
  );
};

export default Soon;
