import {
  Container,
  Flex,
  Text,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import banner from "../assets/img/opti/lore/banner2.avif";
import bannermov from "../assets/img/opti/lore/banner1.avif"
import detail from "../assets/img/opti/lore/Floritura.avif"

export default function Lore() {
  return (
    <>
      <Flex
        position={"relative"}
        justifyContent={"center"}
        mt={{
          base: "5vh",
          sm: "25vh",
        }}
      >
        <Heading
          className="Blore"
          fontSize={{
            base: "4xl",
            md: "4xl",
            lg: "5xl",
          }}
          fontWeight="bold"
          color="brand.600"
        _dark={{
          color: "gray.300",
        }}
          mb={{
            base: "-1%",
            md: "-2%",
            lg: "-4%",
          }}
          lineHeight="shorter"
          textShadow="2px 0 currentcolor"
          
          borderRadius={"64px"}
          p={"10px 25px"}
        >
          Blinks Lore
        </Heading>
      </Flex>
     <Flex 
     className="bannermov"
     visibility={{
      md: 'hidden'
     }}
     backgroundImage={detail}
     backgroundSize={{
      base: '90vw',
      md: "0%"
     }}
     h={{
      base: "4.5em",
      }}
    mt={'5%'}
    
    alignItems={"center"}
    backgroundRepeat={"no-repeat"}
        
        backgroundPosition={"center"}
        pos={'relative'}
        zIndex={'2'}
     ></Flex>
      <Flex
        className="banner"
        h={{
          base: "23em",
          md: "30em"}}
        transform={{
          base: "scale(3.0)",
          sm: "scale(2.0)",
          md: "scale(1.5)",
          lg: "scale(1.0)",
        }}
        mb={{
          base: "60%",
          sm: "40%",
          md: "0%",
        }}
        alignItems={"center"}
        backgroundImage={{
          base: bannermov,
          md: banner}}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"100%"}
        backgroundPosition={"center"}
        mt={{
          base: '-17%',
          md:'-5%'
        }}
        pos={'relative'}
        zIndex={'1'}
        
      >
        <Container className="Clore" textAlign={"center"} maxWidth={"80vw"}>
          <br />
          <Text
            className="Tlore"
            color={"#fff6da"}
            textShadow={
              "0.1px 0.1px 2px #000, 0.1px 0.1px 2px #000, 0.1px 0.1px 5px #000, 0.1px 0.1px 5px #000"
            }
            position={{
              base: "relative",
              lg: "unset",
            }}
            left={{
              base: "30%",
              sm: "25%",
              md: "19%",
              //lg: '0%' not necessary, lg position: unset
            }}
            mb={{
              base: "5%",
              md: "-15%",
            }}
            w={{
              base: "40%",
              sm: "50%",
              md: "65%",
              lg: "100%",
            }}
            fontSize={{
              base: "1.2vw",
              sm: "1.4vw",
            }}
          >
            In a land called Bliss, two races of fairies, the Fay and the Myst,
            lived in harmony with nature and magic. The fairies' power came from
            their connection to the weather and nature. Their magic was kept
            safe in the sacred Natural Mystic Nexus, which was located in Tyra,
            the realm of the Weather Girls. The Nexus also gave birth to a new
            species, the Blinks, who were tasked with protecting the Nexus and
            maintaining the balance between the fairies and the Weather Girls.
          </Text>
        </Container>
      </Flex>
    </>
  );
}
