import React from "react";
import {
  Button,
  useColorMode,
  Box,
  Flex,
  Stack,
  Image,
  useColorModeValue,
  Spacer,
  HStack,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import logo from "../assets/img/opti/logo/logo.avif";
import { NavLink } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      className="nav0"
      h={"4.3em"}
      bg={useColorModeValue("gray.100", "gray.900")}
      px={4}
    >
      <Flex
        className="nav1"
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"5"}
      >
        <IconButton
          size={"lg"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ lg: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <Box display={"inline-block"} className="a-blinks" fontSize={"30px"}>
          <NavLink to="/">
            <Image
              minW={"50px"}
              w={"70px"}
              src={logo}
              alt="logo"
              position={"relative"}
              left={{
                lg: "4vw",
              }}
            />
          </NavLink>
        </Box>
        <HStack spacing={8} alignItems={"center"}>
          <Spacer />
          <HStack
            as={"nav"}
            spacing={12}
            display={{ base: "none", lg: "flex" }}
          >
            <NavLink className="nav_link" to="collection">
              Collections
            </NavLink>
            <NavLink className="nav_link" to="thegame">
              The Game
            </NavLink>
            <NavLink className="nav_link" to="lore">
              Lore
            </NavLink>
            <NavLink className="nav_link" to="features">
              Features
            </NavLink>
            <NavLink className="nav_link" to="roadmap">
              Roadmap
            </NavLink>
            <NavLink className="nav_link" to="projects">
              Projects
            </NavLink>
          </HStack>
        </HStack>

        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            <Button onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button>
          </Stack>
        </Flex>
        <Button mr={"50px"} minW={"70px"} colorScheme="purple">
          Connect
        </Button>
      </Flex>

      {isOpen && colorMode === "light" ? (
        <Box
          position={"absolute"}
          zIndex={"100"}
          w={"150px"}
          textAlign={"center"}
          pb={4}
          bg={"gray.100"}
          display={{ lg: "none" }}
        >
          <Stack as={"nav"} spacing={4}>
            <NavLink className="nav_link" to="/">
              Home
            </NavLink>
            <NavLink className="nav_link" to="collection">
              Collections
            </NavLink>
            <NavLink className="nav_link" to="thegame">
              The Game
            </NavLink>
            <NavLink className="nav_link" to="lore">
              Lore
            </NavLink>
            <NavLink className="nav_link" to="features">
              Features
            </NavLink>
            <NavLink className="nav_link" to="roadmap">
              Roadmap
            </NavLink>
            <NavLink className="nav_link" to="projects">
              Projects
            </NavLink>
          </Stack>
        </Box>
      ) : null}
      {isOpen && colorMode === "dark" ? (
        <Box
          position={"absolute"}
          zIndex={"100"}
          w={"150px"}
          textAlign={"center"}
          pb={4}
          bg={"gray.900"}
          display={{ lg: "none" }}
        >
          <Stack as={"nav"} spacing={4}>
            <NavLink className="nav_link" to="/">
              Home
            </NavLink>
            <NavLink className="nav_link" to="collection">
              Collections
            </NavLink>
            <NavLink className="nav_link" to="thegame">
              The Game
            </NavLink>
            <NavLink className="nav_link" to="lore">
              Lore
            </NavLink>
            <NavLink className="nav_link" to="features">
              Features
            </NavLink>
            <NavLink className="nav_link" to="roadmap">
              Roadmap
            </NavLink>
            <NavLink className="nav_link" to="projects">
              Projects
            </NavLink>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
