import '../../App.css';
import Dashboard from '../Dashboard';
import { ImageSlide } from '../ImageSlide';
import Lore from '../Lore'
import Gaming from '../Gaming';
import Features from '../Features';
import Team from '../Team';
import Footer from '../Footer'
import '../../assets/fonts/Just-Cosmic.otf';
import {  Spinner } from '@chakra-ui/react'
import { Suspense } from 'react';



const Home = () => {
  return (
    <>
   <html className='home'>
    
    <Suspense fallback={<Spinner size={'lg'}/>}>
    
    <Dashboard/>
    
    <ImageSlide/>
    
    
     <Lore/>
          
    <Gaming/>   
    <Features/>  
    
    <Team/>
    
    <Footer/>
    
    </Suspense>
    </html>
    </>
  )
}

export default Home