import React from "react";

import {
  Flex,
  Box,
  SimpleGrid,
  Button,
  chakra,
  Heading,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import org from '../assets/img/opti/features/org.avif'
import composable from '../assets/img/opti/features/composable.avif'
import ficha from '../assets/img/opti/features/ficha.avif'

export default function Features() {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  return (
    <>
      <Heading
        textAlign={"center"}
        mt={{
          base: "70%",
          sm: "150px",
        }}
        fontSize={{
          base: "4xl",
          md: "4xl",
          lg: "5xl",
        }}
        fontWeight="bold"
        color="brand.600"
        _dark={{
          color: "gray.300",
        }}
        lineHeight="shorter"
        textShadow="2px 0 currentcolor"
      >
        Features
      </Heading>
      <Flex
        p={20}
        w="full"
        justifyContent="center"
        alignItems="center"
        pos="absolute"
        display={"contents"}
      >
        <Box
          textAlign={"-webkit-center"}
          /*bg="white"*/
          /*_dark={{
                  bg: "gray.800",
              }}*/
          px={8}
          py={20}
          mx="auto"
        >
          <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
            <SimpleGrid
              w={
                "80%"
              } /*<a target='_blank' rel='noopener noreferrer' href='www.google.es'>PONER FUERA DE ANIMATION ON SCROLL  */
              as={motion.div}
              whileHover={{ scale: 1.05 }}
              /*whileTap={{ scale: 0.9 }}
                    animate={{x:200}}*/
              transition="0.4s linear"
              alignItems="start"
              columns={{
                base: 1,
                md: 2,
              }}
              mb={24}
              spacingY={{
                base: 10,
                md: 32,
              }}
              spacingX={{
                base: 10,
                md: 24,
              }}
            >
              <Box>
                <chakra.h2
                  mb={4}
                  fontSize={{
                    base: "2xl",
                    md: "4xl",
                  }}
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  textAlign={{
                    base: "center",
                    md: "left",
                  }}
                  color="gray.900"
                  _dark={{
                    color: "gray.400",
                  }}
                  lineHeight={{
                    md: "shorter",
                  }}
                >
                  An Organic Story
                </chakra.h2>
                <chakra.p
                  mb={5}
                  textAlign={{
                    base: "center",
                    sm: "left",
                  }}
                  color="gray.600"
                  _dark={{
                    color: "gray.400",
                  }}
                  fontSize={{
                    md: "lg",
                  }}
                >
                  Written direclty from our minds, this text-based Adventure
                  will give you the freedom to decide your own destiny through
                  the different plots of the story. The focus will be on giving
                  the user an immersive narrative experience.
                </chakra.p>
                <Button
                  w={{
                    base: "full",
                    sm: "auto",
                  }}
                  size="lg"
                  bg="gray.900"
                  _dark={{
                    bg: "gray.700",
                  }}
                  _hover={{
                    bg: "gray.700",
                    _dark: {
                      bg: "gray.600",
                    },
                  }}
                  color="gray.100"
                  as="a"
                  onClick={() => {
                    setOverlay(<OverlayOne />);
                    onOpen();
                  }}
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w="full"
                h="full"
                py={48}              
                backgroundImage={org}
                backgroundSize={{
                  base: 'cover',
                  sm: 'contain'}}
                  backgroundPosition={{
                    base:'65%',
                    sm: 'auto'
                  }}
                backgroundRepeat={'no-repeat'}                
              ></Box>
            </SimpleGrid>
          </AnimationOnScroll>

          <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
            <SimpleGrid
              w={
                "80%"
              } /*<a target='_blank' rel='noopener noreferrer' href='www.google.es'>PONER FUERA DE ANIMATION ON SCROLL  */
              as={motion.div}
              whileHover={{ scale: 1.05 }}
              /*whileTap={{ scale: 0.9 }}
              animate={{x:200}}*/
              transition="0.4s linear"
              alignItems="center"
              columns={{
                base: 1,
                md: 2,
              }}
              flexDirection="column-reverse"
              mb={24}
              spacingY={{
                base: 10,
                md: 32,
              }}
              spacingX={{
                base: 10,
                md: 24,
              }}
            >
              <Box
                order={{
                  base: "initial",
                  md: 2,
                }}
              >
                <chakra.h2
                  mb={4}
                  fontSize={{
                    base: "2xl",
                    md: "4xl",
                  }}
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  textAlign={{
                    base: "center",
                    md: "left",
                  }}
                  color="gray.900"
                  _dark={{
                    color: "gray.400",
                  }}
                  lineHeight={{
                    md: "shorter",
                  }}
                >
                  Incorporating Composability
                </chakra.h2>
                <chakra.p
                  mb={5}
                  textAlign={{
                    base: "center",
                    sm: "left",
                  }}
                  color="gray.600"
                  _dark={{
                    color: "gray.400",
                  }}
                  fontSize={{
                    md: "lg",
                  }}
                >
                  You will be able to use and equip NFTs into your main NFT
                  character thanks to The Moonsama Composition Service.
                </chakra.p>
                <Button
                  w={{
                    base: "full",
                    sm: "auto",
                  }}
                  size="lg"
                  bg="gray.900"
                  _dark={{
                    bg: "gray.700",
                  }}
                  _hover={{
                    bg: "gray.700",
                    _dark: {
                      bg: "gray.600",
                    },
                  }}
                  color="gray.100"
                  as="a"
                  onClick={() => {
                    setOverlay(<OverlayOne />);
                    onOpen();
                  }}
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w="full"
                h="full"
                py={48}              
                backgroundImage={composable}
                backgroundSize={{
                  base: '35.5em',
                  sm: 'contain'}}
                  backgroundPosition={{
                    base:'10%',
                    sm: 'auto'
                  }}
                backgroundRepeat={'no-repeat'}
              ></Box>
            </SimpleGrid>
          </AnimationOnScroll>

          <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
            <SimpleGrid
              w={
                "80%"
              } /*<a target='_blank' rel='noopener noreferrer' href='www.google.es'>PONER FUERA DE ANIMATION ON SCROLL  */
              as={motion.div}
              whileHover={{ scale: 1.05 }}
              /*whileTap={{ scale: 0.9 }}
              animate={{x:200}}*/
              transition="0.4s linear"
              alignItems="start"
              columns={{
                base: 1,
                md: 2,
              }}
              mb={24}
              spacingY={{
                base: 10,
                md: 32,
              }}
              spacingX={{
                base: 10,
                md: 24,
              }}
            >
              <Box>
                <chakra.h2
                  mb={4}
                  fontSize={{
                    base: "2xl",
                    md: "4xl",
                  }}
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  textAlign={{
                    base: "center",
                    md: "left",
                  }}
                  color="gray.900"
                  _dark={{
                    color: "gray.400",
                  }}
                  lineHeight={{
                    md: "shorter",
                  }}
                >
                  Collect Fairies and Level Up
                </chakra.h2>
                <chakra.p
                  mb={5}
                  textAlign={{
                    base: "center",
                    sm: "left",
                  }}
                  color="gray.600"
                  _dark={{
                    color: "gray.400",
                  }}
                  fontSize={{
                    md: "lg",
                  }}
                >
                  Players will be able to explore a vast magical world, complete
                  quests, find new fairies, Level Up to make them stronger and
                  battle other players to earn rewards and become the ultimate
                  fairy master.
                </chakra.p>
                <Button
                  w={{
                    base: "full",
                    sm: "auto",
                  }}
                  size="lg"
                  bg="gray.900"
                  _dark={{
                    bg: "gray.700",
                  }}
                  _hover={{
                    bg: "gray.700",
                    _dark: {
                      bg: "gray.600",
                    },
                  }}
                  color="gray.100"
                  as="a"
                  onClick={() => {
                    setOverlay(<OverlayOne />);
                    onOpen();
                  }}
                >
                  Learn More
                </Button>
              </Box>
              <Box
                w="full"
                h="full"
                py={48}              
                backgroundImage={ficha}
                backgroundSize={{
                  base: 'cover',
                  sm: 'contain'}}
                  backgroundPosition={{
                    base:'10%',
                    sm: 'auto'
                  }}
                  border={'solid'}
                  borderColor={'white'}
                backgroundRepeat={'no-repeat'}
              ></Box>
            </SimpleGrid>
          </AnimationOnScroll>
        </Box>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader textAlign={"center"}>WIP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={"center"}>
              Work in progress! Sorry for the inconcenience :(
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
