import "./App.css";
import "./assets/fonts/Just-Cosmic.otf";
import { Box } from "@chakra-ui/react";
import stars from "./assets/img/stars_bg.png";
import Router2 from "./router/Router2";


function App() {
  return (
    <>
      <Box
        className="main-bg"
        minH={"100vh"}
        w={"100%"}
        bgGradient="linear(to-b, #4124A8, #7c77cc 15%, #a3acfb 75%, #cbe0fb 90%)"
        _dark={{
          bgGradient: "linear(to-b, #1a202c 60%, #393e53)",
        }}
      >
        <Box minH={"100vh"} bgImage={stars}>
          <Router2 />
        </Box>
      </Box>
    </>
  );
}

export default App;
