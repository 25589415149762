import React from "react";
import { Box, Button, Image, Link, Text } from "@chakra-ui/react";
import logo from "../assets/img/opti/logo/Logo_blinks_purple.avif";
import raresama from "../assets/img/raresama.svg";
import cloud1 from "../assets/img/opti/cloud/cloud5.avif";
import cloud2 from "../assets/img/opti/cloud/cloud4.avif";
import { motion } from "framer-motion";
import constel1 from "../assets/img/opti/constellation/Constellation1.avif";
import constel2 from "../assets/img/opti/constellation/Constellation2.avif";
import constel3 from "../assets/img/opti/constellation/Constellation3.avif";
import constel4 from "../assets/img/opti/constellation/Constellation4.avif";

export default function Dashboard() {
  return (
    <>
      <Box textAlign={"-webkit-center"} mb={"100px"}>
        <Image
          className="BlinksLogo"
          src={logo}
          alt="Logo"
          zIndex={"50"}
          position={"relative"}
          mt={{
            base: "25%",
            sm: "10%",
          }}
          width={{
            base: "90%", //0em+
            sm: "80%", //30em+ x>480px
            md: "65%", //48em+ x>768px
            lg: "50%", //62em+ x>992px
          }}
        />

        <Link
          className="RaresamaBuy"
          target="_blank"
          variant="outline"
          rel="noopener noreferrer"
          display={"contents"}
          href="https://raresama.com/collections/2109/0x50e32a0e03500f8e0076144a40d62a410c56d36d"
        >
          <Button
            className="RaresamaButton"
            as={motion.div}
            whileHover={{ y: -20 }}
            whileTap={{ scale: 0.9 }}
            h={"70px"}
            width={{
              base: "80%",
              sm: "60%",
              md: "45%",
              lg: "30%",
            }}
            mt={"8%"}
            transition={"0.01s linear"}
            size="lg"
            bg="white.900"
            _dark={{
              bg: "purple.300",
              color: "black",
            }}
            _hover={{
              bg: "gray.200",
              _dark: {
                bg: "purple.500",
              },
            }}
            color="white.100"
            boxShadow={"dark-lg"}
            background={"white"}
          >
            <Text className="Buy" fontSize={"1.35em"}>
              Buy on
            </Text>{" "}
            &nbsp;&nbsp;
            <Image
              className="ButtonLogo"
              src={raresama}
              alt="raresama"
              w={"75%"}
            />
          </Button>
        </Link>

        <Image
          className="nube1"
          alt="clouds"
          src={cloud1}
          w={"25em"}
          overflowX={"hidden"}
          _dark={{
            visibility: "hidden",
          }}
        />
        <Image
          className="nube2"
          alt="clouds"
          src={cloud2}
          w={"25em"}
          overflowX={"hidden"}
          _dark={{
            visibility: "hidden",
          }}
        />

        <Image
          className="constellation1 -appear"
          alt="constellation"
          src={constel1}
          position={"absolute"}
          top={"70%"}
          left={"15%"}
          width={"18em"}
          visibility={{
            base: "hidden",
            md: "visible",
          }}
        />
        <Image
          className="constellation2 -appear"
          alt="constellation"
          src={constel2}
          position={"absolute"}
          top={"15%"}
          left={"15%"}
          width={"13.5em"}
          visibility={{
            base: "hidden",
            md: "visible",
          }}
        />
        <Image
          className="constellation3 -appear"
          alt="constellation"
          src={constel3}
          position={"absolute"}
          top={"70%"}
          right={"15%"}
          width={"13.5em"}
        />
        <Image
          className="constellation4 -appear"
          alt="constellation"
          src={constel4}
          position={"absolute"}
          top={"15%"}
          right={"15%"}
          width={"13.5em"}
          visibility={{
            base: "hidden",
            md: "visible",
          }}
        />
      </Box>
    </>
  );
}
