import React from "react";
import {
  SimpleGrid,
  Flex,
  chakra,
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { /*useState, */ lazy /*, Suspense*/ } from "react";

const Deck = lazy(() => import("./Deck.tsx"));
export default function Gaming() {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  return (
    <>
      <SimpleGrid
        className="DeckAndDiscover"
        mt={{
          base: "45vh",
          sm: "55vh",
          lg: "150px",
        }}
        columns={{
          base: 1,
          md: 2,
        }}
        spacing={0}
      >
        <Flex
          className="deck"
          position={"relative"}
          left={{
            base: "0",
            md: "50%",
            lg: "0",
          }}
          mb={{
            base: "65vh",
            sm: "90vh",
            lg: "0",
          }}
          display={"block"}
          bg="brand.400"
        >
          <Deck />
        </Flex>
        <Flex
          className="Discover"
          direction="column"
          alignItems="start"
          justifyContent="center"
          position={{
            base: "absolute",
            lg: "relative",
          }}
          m={{
            base: "15vh 0vw 0vh 5vw",
            sm: "30vh 0vw 0vh 5vw",
            lg: "0 0 0 0",
          }}
          px={{
            base: 4,
            md: 8,
            lg: 20,
          }}
          py={24}
          zIndex={3}
        >
          <chakra.span
            color="brand.600"
            _dark={{
              color: "gray.300",
            }}
            fontSize="lg"
            textTransform="uppercase"
            fontWeight="extrabold"
          >
            Explore worlds in text
          </chakra.span>
          <chakra.h1
            mb={4}
            fontSize={{
              base: "4xl",
              md: "4xl",
              lg: "5xl",
            }}
            fontWeight="bold"
            color="brand.600"
            _dark={{
              color: "gray.300",
            }}
            lineHeight="shorter"
            textShadow="2px 0 currentcolor"
          >
            Discover Blink
          </chakra.h1>
          <chakra.p
            pr={{
              base: 0,
              lg: 16,
            }}
            mb={4}
            fontSize="lg"
            color="brand.600"
            _dark={{
              color: "gray.400",
            }}
            letterSpacing="wider"
          >
            "Embark on an epic journey through a text-based adventure where
            every decision you make carries the weight of your destiny. Forge
            your own path, overcome obstacles, and reap the rewards of your
            triumphs as collectible NFTs. Your choices will shape your story -
            are you ready to seize your destiny?"
          </chakra.p>
          <Box display="inline-flex" rounded="md">
            <chakra.a
              mt={2}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              fontWeight="bold"
              w="full"
            >
              <Button
                as="a"
                onClick={() => {
                  setOverlay(<OverlayOne />);
                  onOpen();
                }}
              >
                Read more
              </Button>
            </chakra.a>
          </Box>
        </Flex>
      </SimpleGrid>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader textAlign={"center"}>WIP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign={"center"}>
              Work in progress! Sorry for the inconcenience :(
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
