import React from "react";
import {
  SimpleGrid,
  Card,
  CardHeader,
  Heading,
  CardBody,
  CardFooter,
  Button,
  Link,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";
import whatafaaak from "../assets/img/opti/team/whatafaaak.avif";
import laranna from "../assets/img/opti/team/laranna.avif";
import X from "../assets/img/opti/team/X3.avif";
import { motion } from "framer-motion";


export default function Team() {
  return (
    <>
      <Heading
        textAlign={"center"}
        mb={"50px"}
        mt={"-50px"}
        fontSize={{
          base: "4xl",
          md: "4xl",
          lg: "5xl",
        }}
        fontWeight="bold"
        color="brand.600"
        _dark={{
          color: "gray.300",
        }}
        lineHeight="shorter"
        textShadow="2px 0 currentcolor"
      >
        Team
      </Heading>
      <Box
        mb={"150px"}
        display={"flex"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <SimpleGrid
          spacing={10}
          templateColumns={{
            base: "repeat(auto-fill, minmax(150px, 1fr))",
            sm: "repeat(auto-fill, minmax(200px, 1fr))",
          }}
        >
          <Card
            
            borderRadius={"30px"}
            as={motion.div}
            whileHover={{ y: -5 }}
            /*whileTap={{ scale: 0.9 }}
                    animate={{x:200}}*/
            transition="0.2s linear"
          >
            <CardHeader>
              <Image src={laranna} borderRadius={"full"} />
              <Heading size="md" mt={"10px"} mb={"-10px"}>
                {" "}
                Laranna
              </Heading>
            </CardHeader>
            <CardBody padding={"0px"}>
              <Text>
                Co-Founder
                <br />
                Artist
              </Text>
            </CardBody>
            <CardFooter display={"block"}>
            <Link
            className="RaresamaBuy"
            target="_blank"
            variant="outline"
            rel="noopener noreferrer"
            display={"contents"}
            href="https://twitter.com/laranna_nft"
          ><Button 
              bg={'black'}
              ><Image src={X} w={"40px"}/>
              </Button>
              </Link>
            </CardFooter>
          </Card>
          <Card
            borderRadius={"30px"}
            as={motion.div}
            whileHover={{ y: -5 }}
            /*whileTap={{ scale: 0.9 }}
                    animate={{x:200}}*/
            transition="0.2s linear"
          >
            <CardHeader>
              <Image src={whatafaaak} borderRadius={"full"} />
              <Heading size="md" mt={"10px"} mb={"-10px"}>
                {" "}
                Whatafaaak
              </Heading>
            </CardHeader>
            <CardBody padding={"0px"}>
              <Text>
                Co-Founder
                <br />
                Dev
              </Text>
            </CardBody>
            <CardFooter display={"block"}>
            <Link
            className="RaresamaBuy"
            target="_blank"
            variant="outline"
            rel="noopener noreferrer"
            display={"contents"}
            href="https://twitter.com/whatafaaak"
          ><Button 
              bg={'black'}
              ><Image src={X} w={"40px"}/>
              </Button>
              </Link>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}
