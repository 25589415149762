import Logo from "../assets/img/opti/logo/logo.avif";
import {
  Box,
  Container,
  Flex,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      className="Footer"
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      ml={"-10%"}
      mt={"auto"}
    >
      <Container
        className="F1"
        as={Stack}
        maxW={"4xl"}
        py={8}
        px={20}
        h={"auto"}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"} gap={"5"}>
          <Box className="Fimg">
            <img
              src={Logo}
              color={useColorModeValue("gray.700", "white")}
              width={"100px"}
              alt="Logo"
            />
          </Box>
          <Text className="Ftxt" fontSize={"sm"}>
            © 2023 Blinks. All rights reserved
          </Text>

          <Stack className="Frss" align={"flex-start"}>
            <ListHeader>Follow Us</ListHeader>
            <Link href={"#"}>Discord</Link>
            <Link href={"#"}>Twitter</Link>
            <Link href={"#"}>Telegram</Link>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
}
