import fay1 from "../assets/img/fay1.JPG";
import fay2 from "../assets/img/fay2.JPG";
import fay3 from "../assets/img/fay3.JPG";
import fay4 from "../assets/img/fay4.JPG";
import fay5 from "../assets/img/fay5.JPG";
import fay6 from "../assets/img/fay6.JPG";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Heading, Box, Text, Image } from "@chakra-ui/react";
export const ImageSlide = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      className="imageslide"
      id="imageslides"
      p={"0 0 50px 0"}
      position={"relative"}
    >
      <Box className="container">
        <Box className="row">
          <Box className="col-12">
            <Box
              className="imageslide-bx wow zoomIn"
              /*background={useColorModeValue('gray.100', 'gray.900')}
              borderRadius={'64px'}*/
              textAlign={"center"}
              p={"60px 50px"}
              mt={"40vh"}
            >
              <Heading
                fontSize={{
                  base: "4xl",
                  md: "4xl",
                  lg: "5xl",
                }}
                fontWeight="bold"
                color="brand.600"
                _dark={{
                  color: "gray.300",
                }}
                lineHeight="shorter"
                textShadow="2px 0 currentcolor"
              >
                Fay's Collection
              </Heading>
              <Text
                className="imageslide p"
                margin={"14px 0 75px 0"}
                fontSize="lg"
                color="brand.600"
                _dark={{
                  color: "gray.400",
                }}
                letterSpacing="wider"
              >
                This collection of little Fairies is the perfect combination of
                adorable, fun, cheerful and elegance.
                <br />
                These little creatures have a special connection to the weather
                elements and are part of something much larger...
              </Text>
              <Carousel
                className="owl-carousel owl-theme imageslide-slider"
                sliderClass=""
                responsive={responsive}
                infinite={true}
              >
                <Box className="item">
                  <Image src={fay1} alt="fay1" />
                </Box>
                <Box className="item">
                  <Image src={fay2} alt="fay2" />
                </Box>
                <Box className="item">
                  <Image src={fay3} alt="fay3" />
                </Box>
                <Box className="item">
                  <Image src={fay4} alt="fay4" />
                </Box>
                <Box className="item">
                  <Image src={fay5} alt="fay5" />
                </Box>
                <Box className="item">
                  <Image src={fay6} alt="fay6" />
                </Box>
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
