import { Routes, Route } from "react-router-dom"

import Home from "../components/pages/Home"
import Navbar from "../components/Navbar"
import Soon from "../components/pages/Soon"


const Router2 = () => {
  return (
    <>
    <Navbar/>
    <Routes>
    
        <Route path="collection" element={<Soon/>}/>
        <Route path="thegame" element={<Soon/>}/>
        <Route path="lore" element={<Soon/>}/>
        <Route path="features" element={<Soon/>}/>
        <Route path="roadmap" element={<Soon/>}/>
        <Route path="projects" element={<Soon/>}/>
        <Route path="/" element={<Home/>}/>
    </Routes>
    
    </>
  )
}

export default Router2